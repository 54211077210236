import React from 'react';
import './ModulesPage.css';
import Tabs from '../components/tabs/Tabs';
import CourseList from '../components/courses/CourseList';
import { basisModules, spesialistModules, tjenestetilpassetModules } from '../components/courses/CourseModules';

const ModulesPage: React.FC = () => {
    const tabs = [
        {
            title: 'Basisnivå',
            component: () =>
                <CourseList modules={basisModules} />
        },
        {
            title: 'Tjenestetilpasset',
            component: () =>
                <CourseList modules={tjenestetilpassetModules} />
        },
        {
            title: 'Spesialist',
            component: () =>
                <CourseList modules={spesialistModules} />
        },
    ];

    return (
        <div className="modules-page">
            <Tabs tabs={tabs} />
        </div>
    );
};

export default ModulesPage;
