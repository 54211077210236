import React from 'react';
import './HomePage.css';

const HomePage: React.FC = () => {
    return (
        <div className="page-container">
            <div className="image-container">
                <div className="arrow-container">
                <div className="image-arrow"></div>
                </div>
            </div>

            <div className="main-content">
                <div className="text-section left">
                    <span className='home-title'>Om Ajour</span>
                    <p>
                        I regi av HEVE-prosjektet, har vi gleden av å introdusere begynnelsen på en ny, digital plattform: <span className='ajour2'>Ajour</span>.
                    </p>
                    <p>
                        Ajour har som mål å gjøre det enklere for politifolk å holde oversikt over individuell trening.
                        Dette inkluderer både fysisk og digital trening.
                        På sikt ønsker vi å samle all trening i politiet på én nasjonal plattform, slik at vi kan forsikre oss om at
                        alle har tilgang på kvalitetssikret, relevant, oppdatert, og oversiktlig informasjon.
                    </p>
                    <p>
                        Denne plattformen er under utvikling, så gjerne kom med tilbakemeldinger og innspill!
                    </p>
                </div>

                <div className="image-section right"></div>

                <div className="image-section left"></div>

                <div className="text-section right">
                    <span className='home-title'>Hvorfor Ajour?</span>
                    <p>
                        I dag er det flere ulike systemer som brukes for å holde oversikt over trening, både digital og fysisk, i politiet.
                        Ofte er dette systemer som er utviklet internt i distriktene, gjerne av enkeltindivider, som ikke snakker sammen på tvers av regioner.
                    </p>
                    <p>
                        Dette fører til at det er vanskelig å få oversikt over trening både på individnivå og på regionsnivå.

                    </p>
                </div>
            </div>
            <div className="image-banner">
                <div className="image-overlay">
                    <span className="banner-text">Er du fortsatt i tåka? Her kommer en kjapp innføring.</span>
                </div>
            </div>

            <div className="sub-content">
                <span className='home-title'>Hvordan fungerer det?</span>
                <p>Denne seksjonen er under utvikling...</p>
            </div>
        </div >
    );
};

export default HomePage;
