import { SCORM_CLOUD_BASE_URL, SCORM_CLOUD_AUTH } from './config';

/**
 * Fetches data from a paginated API endpoint recursively.
 * 
 * @param endpoint - The endpoint URL to fetch the data from.
 * @param params - The query parameters to include in the request.
 * @param responseKey The key in the response JSON that contains the desired data (e.g., 'courses', 'registrations').
 * @param more The token for fetching the next page of results, if available.
 * @returns A promise that resolves to the accumulated results from all pages.
 */
export async function fetchPaginatedData(
    endpoint: string,
    params: { [key: string]: string },
    responseKey: string,
    more: string = ''
): Promise<any[]> {
    let results: any[] = [];
    try {
        const url = new URL(`${SCORM_CLOUD_BASE_URL}/${endpoint}`);
        if (more) {
            url.searchParams.append('more', more);
        } else {
            Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
        }

        const response = await fetch(url.toString(), {
            headers: {
                'Authorization': SCORM_CLOUD_AUTH
            }
        });

        const data = await response.json();
        results = results.concat(data[responseKey]);

        if (data.more) {
            const moreResults = await fetchPaginatedData('registrations', params, responseKey, data.more);
            results = results.concat(moreResults);
        }

        return results;
    } catch (error) {
        console.log(endpoint);
        console.error('Error fetching data:', error);
        return [];
    }
}