import './Layout.css';
import { Outlet, NavLink } from 'react-router-dom';
import { useLogout } from './hooks/useLogout';
import { useAuth } from './hooks/useAuth';
import logoImage from './components/images/police_ajour.png';
import bottomImage from './components/images/riksv.png';
import { UserRole } from './types/UserTypes';

export const Layout = () => {
    const { userdata } = useAuth();
    const { logout } = useLogout();

    const handleLogout = () => {
        logout();
    };

    return (
        <div className='layout'>
            <nav className='navbar'>
                <div className="logo-item">
                    <NavLink to="/">
                        <img src={logoImage} alt="Logo" />
                    </NavLink>
                </div>
                <ul>
                    <li><NavLink to="/home" className={({ isActive }) => isActive ? "active" : undefined}>HJEM</NavLink></li>
                    {/*<li><NavLink to="/dictionary" className={({ isActive }) => isActive ? "active" : undefined}>OPPSLAGSVERK</NavLink></li>*/}
                    <li><NavLink to="/modules" className={({ isActive }) => isActive ? "active" : undefined}>LEKSJONER</NavLink></li>
                    <li><NavLink to="/mypage" className={({ isActive }) => isActive ? "active" : undefined}>MIN SIDE</NavLink></li>
                    {userdata?.role >= UserRole.Instructor && (
                        <li><NavLink to="/courseinstructor" className={({ isActive }) => isActive ? "active" : undefined}>INSTRUKTØR</NavLink></li>
                    )}
                </ul>
                <div className="logout-button"><button onClick={handleLogout}>LOGG UT</button></div>

            </nav>

            <div className='content'>
                <Outlet />
            </div>

            <nav className='footer'>
                <img src={bottomImage} alt="Footer Logo" className="footer-logo" />
            </nav>
        </div>
    );
};
