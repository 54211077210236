import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { getUserByEmail } from '../api/database/UserService';
import { registerUserForClass } from '../api/database/ClassService';

interface CourseParticipantsProps {
  selectedCourse: any;
}

const CourseParticipants: React.FC<CourseParticipantsProps> = ({ selectedCourse }) => {
  const { userdata } = useAuth();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccess('');


    try {
        const user = await getUserByEmail(email);
        if (user) {
            await registerUserForClass(selectedCourse.cid, user.uid, user.email, user.fullName);
        }
    } catch (err: unknown) {
        if (err instanceof Error) {
            setError('Failed to register user: ' + err.message);
        } else {
            setError('Failed to register user due to an unknown error.');
        }
    }
};

  return (
    <div className="register-user-wrapper">
        <h1>Registrer bruker</h1>
        <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='E-post'
                    required
                />
            </div>
            <button type="submit">Registrer deltaker</button>
            {error && <div className="error">{error}</div>}
            {success && <div className="success">{success}</div>}
        </form>
    </div>
);

};

export default CourseParticipants;
