import { useContext } from 'react';
import { createContext, useReducer, useEffect } from 'react';
import { getUserDetails } from '../api/database/UserService';

const AuthContext = createContext<any>(null);

const authReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, userdata: action.payload };
        case 'LOGOUT':
            return { ...state, userdata: null };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(authReducer, null);

    useEffect(() => {
        const uid = localStorage.getItem('uid');
        if (uid) {
            const fetchAndSetUserDetails = async () => {
                const userDetails = await getUserDetails(uid);
                if (userDetails) {
                    dispatch({ type: 'LOGIN', payload: userDetails });
                }
            };
            fetchAndSetUserDetails();
        }
    }, []);

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}