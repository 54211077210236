import React from 'react';
import { BsSliders } from 'react-icons/bs';
import './TeachingPlanBank.css';
import ListCourses from '../components/templates/ListCourses';

const TeachingPlanBank: React.FC = () => {

    const handleFilterButtonClick = () => {
        // Dummy function to be run when the filter button is clicked
        // This must be changed to actually mark something on the pages as chosen
        // Must also actually filter out courses
        console.log("Filter button clicked!");
    };

    const handleSmallerFilterButtonClick = () => {
        // Dummy function to be run when the filter button is clicked
        // This must be changed to actually mark something on the pages as chosen
        // Must also actually filter out courses
        console.log("A filter button is clicked!");
    };

    return (
        <div className="teachingplanbank-page">
            <div className="teachingplanbank-box-a">
                <h1 className="title">
                    Undervisningsplanbank
                </h1>
                <div className='text'>
                    I denne banken finner du alle tilgjengelige undervisningplaner, filtrert etter emne.
                </div>
            </div>
            <div className="teachingplanbank-search">
                <div className="teachingplanbank-box1">
                    <button className="teachingplanbank-button1" onClick={handleFilterButtonClick}>
                        <BsSliders /> Filtrer søk
                    </button>
                </div>
                <div className="teachingplanbank-box2">
                    <div className="teachingplanbank-label1">
                        Nivå:
                    </div>
                    <div className="teachingplanbank-buttons">
                        <div className="xxx1">
                            <button className="teachingplanbank-buttons1" onClick={handleSmallerFilterButtonClick}></button>
                            Basis
                        </div>
                        <div className="xxx2">
                            <button className="teachingplanbank-buttons2"  onClick={handleSmallerFilterButtonClick}></button>
                            Tjenestetilpasset
                        </div>
                        <div className="xxx3">
                            <button className="teachingplanbank-buttons3"  onClick={handleSmallerFilterButtonClick}></button>
                            Spesialistnivå
                        </div>
                    </div>
                    <div className="teachingplanbank-label1">
                        Emne:
                    </div>
                    <div className="teachingplanbank-buttons">
                        <div className="xxx4">
                            <button className="teachingplanbank-buttons1" onClick={handleSmallerFilterButtonClick}></button>
                            Beredskap
                        </div>
                        <div className="xxx5">
                            <button className="teachingplanbank-buttons2"  onClick={handleSmallerFilterButtonClick}></button>
                            Dokumentasjon
                        </div>
                        <div className="xxx6">
                            <button className="teachingplanbank-buttons3"  onClick={handleSmallerFilterButtonClick}></button>
                            Fysisk aktivitet og helse
                        </div>
                    </div>
                    <div className="teachingplanbank-buttons">
                        <div className="xxx7">
                            <button className="teachingplanbank-buttons1" onClick={handleSmallerFilterButtonClick}></button>
                            Fysisk makt
                        </div>
                        <div className="xxx8">
                            <button className="teachingplanbank-buttons2"  onClick={handleSmallerFilterButtonClick}></button>
                            Lover og regler
                        </div>
                        <div className="xxx9">
                            <button className="teachingplanbank-buttons3"  onClick={handleSmallerFilterButtonClick}></button>
                            Profesjon
                        </div>
                    </div>
                    <div className="teachingplanbank-buttons">
                        <div className="xxx10">
                            <button className="teachingplanbank-buttons1" onClick={handleSmallerFilterButtonClick}></button>
                            Sanitet
                        </div>
                        <div className="xxx11">
                            <button className="teachingplanbank-buttons2"  onClick={handleSmallerFilterButtonClick}></button>
                            Operativ tjeneste
                        </div>
                        <div className="xxx12">
                            <button className="teachingplanbank-buttons3"  onClick={handleSmallerFilterButtonClick}></button>
                            Etterforskning for patruljen
                        </div>
                    </div>
                </div>
            </div>
            <div className="empty-box-for-filling-list"></div>
            < ListCourses />
            <div className="empty-box-for-filling"></div>
        </div>
    );
};

export default TeachingPlanBank;

