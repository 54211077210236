import React, { useState } from 'react';
import './TeachingPage.css';
import CourseDetails from './CourseDetails';
import { HiPlusSmall } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';

const TeachingPage: React.FC = () => {
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const navigate = useNavigate(); // Move useNavigate here
  
  const handleButtonClick = (buttonIndex: number) => {
    setActiveButton(buttonIndex);
  };

  const renderComponent = () => {
    switch (activeButton) {
      case 1:
        return <CourseDetails />;
      case 2:
        return null;
    }
  };
  
  const handleCreateCourseButton = () => {
    navigate('/createcourse');
  };

  return (
    <div className="teaching-page">
      <div className="box-a">
        <div className="left-box-a">
          <h1 className="title">Undervisning</h1>
          <div className='text'>
            Her finner du oversikt over leksjoner du skal undervise i, eller tidligere har undervist i. For hver
            leksjon kan du hente deltagerliste og undervisningsplan, se påkrevde forkunnskaper, samt publisere beskjeder og generere QR-kode for å registrere oppmøte.
            Under 'Opprett undervisning' kan du opprette en leksjon og legge inn nødvendig informasjon for deltakere.
          </div>
        </div>
        <div className="right-box-a">
          <button className="create-course-box-a" onClick={handleCreateCourseButton}>
              <HiPlusSmall />
            <div className="create-course-text">
              Opprett undervisning
            </div>
          </button>
        </div>
      </div>

      <div className="box-b">
        <div className="button2-container">
          <button
            className={`module-button2 ${activeButton === 1 ? 'active' : ''}`}
            onClick={() => handleButtonClick(1)}
          >
            Kommende
          </button>
          <button
            className={`module-button2 ${activeButton === 2 ? 'active' : ''}`}
            onClick={() => handleButtonClick(2)}
          >
            Fullført
          </button>
        </div>
        <div className="underline2"></div>
      </div>

      <div className="box-c">
        {renderComponent()}
      </div>

      <div className="empty-box-for-filling"></div>
    </div>
  );
};

export default TeachingPage;
