import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import './ListParticipants.css';
import { RiCloseLargeFill } from 'react-icons/ri';
import { PiBookOpenText } from "react-icons/pi";
import { UserDetailsForCourse } from '../../types/UserTypes';
import { getUsersByCourse } from '../../api/database/UserService';

interface ListParticipantsProps {
  selectedCourse: any;
}

const placeholderColumns: GridColDef[] = [
  { field: 'placeholder1', headerName: 'Ingen data tilgjengelig', width: 350, headerClassName: 'header-placeholder', cellClassName: 'cell-placeholder' },
  { field: 'placeholder2', headerName: '', width: 280, headerClassName: 'header-placeholder', cellClassName: 'cell-placeholder' },
];


const ListParticipants: React.FC<ListParticipantsProps> = ({ selectedCourse }) => {
  const [list, setList] = useState<UserDetailsForCourse[]>([]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        const fetchedList = await getUsersByCourse(selectedCourse.cid);
        if (fetchedList) {
          setList(fetchedList);
        }
      } catch (error) {
        console.error('Error fetching list:', error);
      }
    };

    fetchList();
  }, [selectedCourse]);


  const columns: GridColDef[] = [
    { field: 'fullName', resizable: false, headerName: 'Navn', width: 350, headerClassName: 'header-name', cellClassName: 'cell-name' },
    { field: 'email', resizable: false, headerName: 'E-post', width: 280, headerClassName: 'header-email', cellClassName: 'cell-email' },
    {
      field: 'actions',
      headerName: '',
      resizable: false,
      headerClassName: 'header-action',
      width: 200,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="book-button" onClick={() => handleBook(params.row.id)}>
            <PiBookOpenText style={{ fontSize: '2rem', marginRight: '30px', marginTop: '-5px' }} /> 
          </button>
          <button className="delete-button" onClick={() => handleDelete(params.row.id)}>
            <RiCloseLargeFill style={{ fontSize: '1.5rem', strokeWidth: '1.5', marginTop: '-3px' }} />
          </button>
        </div>
      ),
    },
  ];
  
  let modifiedColumns = columns;
  
  const handleButtonClickApprove = () => {
    // Action to perform when the approved button is clicked
  };
  
  const handleDelete = (ids: string[]) => {
    setList(prevList => prevList.filter(item => !ids.includes(item.uid)));
  };
  
  const handleBook = (id: number) => {
    // Action to perform when the book button is clicked
    console.log("Book button clicked for id:", id);
  };
  
  const getRowClassName = () => 'custom-row-class';
  
  return (
    <div className="list-container-course">
      <div className="table-container">
        <DataGrid
          rows={list.length > 0 ? list : [{ id: 'placeholder', placeholder1: '', placeholder2: '' }]} // Ensure DataGrid has a row to work with
          columns={modifiedColumns}
          getRowClassName={getRowClassName}
          pageSizeOptions={[10]}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnResize
        />
      </div>
      
      <div className="approval-button-container">
        <button className="approval-list-button" onClick={handleButtonClickApprove}>
          Godkjenn deltakerliste!
        </button>
      </div>
      
      <div className="empty-box-for-filling"></div>
    </div>
  );
};
export default ListParticipants;
