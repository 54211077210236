import React, { useState, useEffect } from 'react';
import { UserRole } from '../../types/UserTypes';
import { createUser, createUserDetails } from '../../api/database/UserService';
import { useLogin } from '../../hooks/useLogin';
import './Register.css';

const Register: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<UserRole>(UserRole.User);
    const [level, setLevel] = useState('basis');
    const { login, isLoading, error } = useLogin();

    {/*useEffect(() => {
        alert("!!! NB !!!\nIkke bruk ekte innloggingsinformasjon.\nVi har tilgang på all informasjonen du legger inn under testing.\nBruk fiktive data.\n\nSkriv ned informasjonen på et ark eller lignende.");
    })*/}

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const uid = await createUser(username, password);
        if (uid) {
            const userDetailsSuccess = await createUserDetails(uid, fullName, email, role, level);
            if (userDetailsSuccess) {
                console.log("User registration successful");
                await login(username, password);

            } else {
                console.error("Failed to create user details");
            }
        } else {
            console.error("Failed to create user");
        }
    };

    return (
        <>
            <h1 className="login-title">Registrer deg</h1>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Fullt navn"
                    />
                </div>
                <div className="input-wrapper">
                    <input
                        className="register_input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-post"
                    />
                </div>
                <div className="input-wrapper">
                    <input
                        className="register_input"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Brukernavn"
                    />
                </div>
                <div className="input-wrapper">
                    <input
                        className="register_input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Passord"
                    />
                </div>
                <div className="input-wrapper">
                    <select
                        className="register_select"
                        value={role}
                        onChange={(e) => setRole(Number(e.target.value))}
                    >
                        <option value={UserRole.User}>Student</option>
                        <option value={UserRole.Instructor}>Instruktør</option>
                        <option value={UserRole.Admin}>Admin</option>
                    </select>
                </div>
                <div className="input-wrapper">
                    <select
                        className="register_select"
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                    >
                        <option value="basis">Basisnivå</option>
                        <option value="tjenestetilpasset">Tjenestetilpasset</option>
                        <option value="spesialist">Spesialist</option>
                    </select>
                </div>
                <button disabled={isLoading} type="submit" className="register-btn">Registrer</button>
                {error && <div className="error">{error}</div>}
            </form>
        </>
    );
};

export default Register;