export enum UserRole {
    User = 1,
    Instructor = 2,
    Admin = 3
}

export interface UserCredentials {
    uid: string;
    username: string;
    password: string;
}

export interface UserDetailsForCourse {
    fullName: string;
    email: string;
    uid: string;
}