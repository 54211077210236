import { DATA_API_BASE_URL } from "./config";
import { ClassType } from "../../types/ClassType";
import { v4 as uuidv4 } from 'uuid';


/**
 * Retrieves the classes for a given instructor. This data will be shown on the
 * instructor's dashboard under "upcoming classes" and "past classes".
 *
 * @param {string} uid - The unique identifier of the instructor.
 * @returns {Promise<ClassType[] | null>} - A promise that resolves to an array of ClassType objects or null if an error occurs.
 */
export async function getInstructorClasses(
    uid: string
): Promise<ClassType[] | null> {
    try {
        const response = await fetch(
            `${DATA_API_BASE_URL}/Course?$filter=host eq ${uid}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        return data.value;
    } catch (error) {
        console.error("Error fetching courses:", error);
        return null;
    }
}

/**
 * Posts a new class to the database. This function should be called when an
 * instructor creates a new class from the instructor dashboard.
 *
 * @param {ClassType} classType - The class object to be posted.
 */
export async function postClass(classType: ClassType) {
    try {
        await fetch(`${DATA_API_BASE_URL}/Course`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(classType),
        });
    } catch (error) {
        console.error("Error posting class:", error);
    }
}

export async function connectCourseToUser(cid: string, uid: string) {
    try {
        await fetch(`${DATA_API_BASE_URL}/CourseUser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cid: cid,
                uid: uid,
            }),
        });
        console.log("Course connected to user");
    } catch (error) {
        console.error("Error connecting course to user:", error);
    }
};

export async function createCourse(code: string, name: string, time: Date, host: string, place: string, duration: string, prerequisites: string[]){
    const cid = uuidv4();
    try {
        await fetch(`${DATA_API_BASE_URL}/Course`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cid: cid,
                code: code,
                name: name,
                time: time,
                host: host,
                place: place,
                duration: parseInt(duration),
                prerequisites: prerequisites,
            }),
        });
        return cid;
    } catch (error) {
        console.error("Error creating course:", error);
        return null;
    }
};

/**
 * Registers a user for a class. This function should be called when a user
 * is added to a class from the instructor dashboard.
 *
 * @param {string} cid - The unique identifier of the class.
 * @param {string} uid - The unique identifier of the user.
 */
export async function registerUserForClass(cid: string, uid: string, email: string, name: string) {
    try {
        await fetch(`${DATA_API_BASE_URL}/CourseUser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cuid: uuidv4(),
                cid: cid,
                uid: uid,
                attendance: 0,
                email: email,
                fullName: name
            }),
        });
    } catch (error) {
        console.error("Error registering user for class:", error);
    }
}

/**
 * Retrieves a class by its ID from the database.
 *
 * @param {string} cid - The ID of the class to retrieve.
 * @returns {Promise<ClassType | null>} - A promise that resolves to the retrieved class or null if not found.
 */
export async function getClassesById(cid: string): Promise<ClassType | null> {
    try {
        const response = await fetch(
            `${DATA_API_BASE_URL}/Course?$filter=cid eq '${cid}'`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        return data.value[0];
    } catch (error) {
        console.error("Error fetching courses:", error);
        return null;
    }
}

/**
 * Retrieves the classes for a given user. This data will be shown on the
 * user's "my page" under "upcoming classes" and "past classes".
 *
 * @param {string} uid - The unique identifier of the user.
 * @returns {Promise<ClassType[] | null>} - A promise that resolves to an array of ClassType objects or null if an error occurs.
 */
export async function getClassesForUser(
    uid: string
): Promise<ClassType[] | null> {
    try {
        // 1. Fetch the class IDs (cids) associated with the user
        const response = await fetch(
            `${DATA_API_BASE_URL}/CourseUser?$filter=uid eq ${uid}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();

        // 2. Extract the class IDs
        const classIds = data.value.map((item: { cid: string }) => item.cid);

        // 3. Batch fetch class details using getClassesById
        const classPromises = classIds.map(getClassesById);
        const classes = await Promise.all(classPromises);

        // 4. Filter out any null results (in case a class ID wasn't found)
        return classes.filter((cls): cls is ClassType => cls !== null);
    } catch (error) {
        console.error("Error fetching classes for user:", error);
        return null;
    }
}
