import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { createCourse } from '../api/database/ClassService';
import './CreateCoursePage.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CreateCoursePage: React.FC = () => {
    const { userdata } = useAuth();
    const [code] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [startTime, setStartTime] = useState(new Date());
    const [place, setPlace] = useState<string>('');
    const [duration, setDuration] = useState<string>('');
    const [prerequisites] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!userdata || userdata.role < 2) {
            setError('You do not have permission to create a course.');
            return;
        }

        try {
            const prereqArray = prerequisites.split(',').map((item) => item.trim());
            await createCourse(code, name, startTime, userdata.uid, place, duration, prereqArray);            
            setSuccess('Kurs opprettet');
        } catch (err: unknown) {
            if (err instanceof Error) {
                setError('Failed to create course: ' + err.message);
            } else {
                setError('Failed to create course due to an unknown error.');
            }
        }
    };

    return (
        <div className="create-course-wrapper">
            <h1>Opprett nytt kurs</h1>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Kursnavn'
                        required
                    />
                </div>
                <div className="input-wrapper">
                    <DatePicker
                    selected={startTime}
                    onChange={(date) => date && setStartTime(date)}                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText='Tidspunkt!'
                />
                </div>
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={place}
                        onChange={(e) => setPlace(e.target.value)}
                        placeholder='Sted'
                        required
                    />
                </div>
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        placeholder='Varighet'
                        required
                    />
                </div>
                <button type="submit">Create Course</button>
                {error && <div className="error">{error}</div>}
                {success && <div className="success">{success}</div>}
            </form>
        </div>
    );
};

export default CreateCoursePage;
