import { useState } from 'react';
import { useLogin } from '../../hooks/useLogin'
import './Login.css';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { login, isLoading, error } = useLogin();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await login(username, password);
    };

    return (
        <>
            <h1 className="login-title">Logg inn</h1>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input
                        placeholder="Brukernavn"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="input-wrapper">
                    <input
                        placeholder="Passord"
                        type="password"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button disabled={isLoading} type="submit" className="login-btn">Logg inn</button>
                {error && <div className="error">{error}</div>}
            </form>
        </>
    );
};

export default Login;

