import React, { useState } from 'react';
import './CourseInstructorPage.css';
import TeachingPlanBank from './TeachingPlanBank';
import TeachingPage from './TeachingPage';
import Test from './FeedbackPage';

const CourseInstructorPage: React.FC = () => {
  const [activeButton, setActiveButton] = useState<number | null>(null);

  const handleButtonClick = (buttonIndex: number) => {
    setActiveButton(buttonIndex);
  };

  const renderContent = () => { //Renders based on the pressed button on the page
    switch (activeButton) {
      case 1:
        return <TeachingPage />;
      case 2:
        return <Test/>;
      case 3:
        return <TeachingPlanBank />;
      default:
        return <TeachingPage />;
    }
  };

  return (
    <>
      <div className="modules-container1">
        <div className="button1-container">
          <button
            className={`module-button1 ${activeButton === 1 ? 'active' : ''}`}
            onClick={() => handleButtonClick(1)}
          >
            Undervisning
          </button>
          <button
            className={`module-button1 ${activeButton === 2 ? 'active' : ''}`}
            onClick={() => handleButtonClick(2)}
          >
            Tilbakemeldinger
          </button>
          <button
            className={`module-button1 ${activeButton === 3 ? 'active' : ''}`}
            onClick={() => handleButtonClick(3)}
          >
            Undervisningsplaner
          </button>
        </div>
      </div>
      <div className="underline1"></div>

      {renderContent()}
    </>
  );
};

export default CourseInstructorPage;
