import { fetchPaginatedData } from './ServiceUtilities';

/**
 * Fetches paginated courses from the specified base URL.
 * 
 * @param params - The query parameters to include in the request.
 * @returns A promise that resolves to an array of courses.
 */
async function fetchPaginatedCourses(params: { [key: string]: string }): Promise<any[]> {
    return fetchPaginatedData('courses', params, 'courses');
}


/**
 * Retrieves courses based on the specified level.
 */
export const levelToFetchFunction: { [key: string]: () => Promise<any[]> } = {
    'basis': () => fetchPaginatedCourses({ tags: 'basis' }),
    'tjenestetilpasset': () => fetchPaginatedCourses({ tags: 'tjenestetilpasset' }),
    'spesialist': () => fetchPaginatedCourses({ tags: 'spesialist' }),
};


/**
 * Retrieves courses based on the specified level.
 * @param level - The level of the courses to retrieve.
 * @returns A promise that resolves to the fetched courses.
 */
export async function getCourses(level: string) {
    const fetchFunction = levelToFetchFunction[level];
    return fetchFunction();
}


/**
 * Retrieves courses by tag and level.
 * @param level - The level of the courses to retrieve.
 * @param tag - The tag to filter the courses by.
 * @returns A promise that resolves to an array of courses matching the given tag and level.
 */
export async function getCoursesByTag(level: string, tag: string): Promise<any[]> {
    const fetchFunction = levelToFetchFunction[level];
    const courses = await fetchFunction();
    return courses.filter(course => course && course.tags && course.tags.includes(tag));
}