import { DATA_API_BASE_URL } from './config';
import { UserCredentials, UserRole, UserDetailsForCourse } from '../../types/UserTypes';
import { v4 as uuidv4 } from 'uuid';

export async function verifyUserCredentials(username: string, password: string) {
    try {
        const response = await fetch(`${DATA_API_BASE_URL}/User`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        const user = data.value.find((user: UserCredentials) => user.username === username && user.password === password);
        if (user) return user.uid;
        return null;
    } catch (error) {
        console.error('Error verifying user credentials:', error);
        return null;
    }
}

export async function getUserDetails(uid: string) {
    try {
        const response = await fetch(`${DATA_API_BASE_URL}/UserDetails`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        const userDetails = data.value.find((user: any) => user.uid === uid);
        return userDetails;
    } catch (error) {
        console.error('Error fetching user details:', error);
        return null;
    }
}

export async function createUser(username: string, password: string) {
    const uid = uuidv4();
    try {
        const response = await fetch(`${DATA_API_BASE_URL}/User`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uid,
                username,
                password,
            }),
        });
        return uid;
    } catch (error) {
        console.error('Error creating user:', error);
        return null;
    }
}

export async function createUserDetails(uid: string, fullName: string, email: string, role: UserRole, level: string) {
    try {
        const response = await fetch(`${DATA_API_BASE_URL}/UserDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid, fullName, email, picture: null, role, level })
        });
        return response.ok;
    } catch (error) {
        console.error('Error creating user details:', error);
        return false;
    }
}

export async function getUsersByCourse(cid: string): Promise<UserDetailsForCourse[] | null> {
    try {
        // Ensure the cid is treated as a GUID in the query
        const query = `$filter=cid eq ${cid}`;

        const response = await fetch(`${DATA_API_BASE_URL}/CourseUser?${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Database operation failed');
        }

        const data: any = await response.json();
        
        // Add a unique `id` property to each row
        const rowsWithId = data.value.map((row: any) => ({
            id: row.uid,  // Assuming `uid` is unique and can be used as `id`
            ...row
        }));

        return rowsWithId;
    } catch (error: any) {
        console.error('Error fetching users by course:', error);
        return null;
    }
}




export async function getUserByEmail(email: string) {
    try {
        const encodedEmail = encodeURIComponent(email);
        const response = await fetch(`${DATA_API_BASE_URL}/UserDetails?$filter=email eq '${encodedEmail}'`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        if (Array.isArray(data.value) && data.value.length > 0) {
            return data.value[0]; // Assuming email is unique, return the first user found
        } else {
            console.log('No user found with the given email:', email);
            return null; // No user found
        }
    } catch (error) {
        console.error('Error fetching user by email:', error);
        return null;
    }
}