import React, { useState } from 'react';
import './Tabs.css';

interface TabProps {
    tabs: {
        title: string;
        component: React.ElementType;
    }[];
}

const Tabs: React.FC<TabProps> = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const ActiveComponent = tabs[activeTab].component;

    return (
        <div className="tabs-container">
            <div className="tabs-buttons-container">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={`tab-button ${index === activeTab ? 'active' : ''}`}
                        onClick={() => setActiveTab(index)}
                    >
                        {tab.title}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                <ActiveComponent />
            </div>
        </div>
    );
};

export default Tabs;
