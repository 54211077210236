import { useState } from 'react';
import { useAuth } from './useAuth';
import { verifyUserCredentials, getUserDetails } from '../api/database/UserService';

export const useLogin = () => {
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { dispatch } = useAuth();

    const login = async (username: string, password: string) => {
        setIsLoading(true);
        setError('');

        // Liten hack enn så lenge
        if (username === 'admin') {
            const dummyUserDetails = {
                uid: 'AFB11829-7F7C-4D91-9791-C7BCDEB1293A',
                fullName: 'Admin User',
                email: 'admin@example.com',
                picture: null,
                role: 3,
                level: 'spesialist'
            }
            localStorage.setItem('uid', dummyUserDetails.uid);
            dispatch({ type: 'LOGIN', payload: dummyUserDetails });
            setIsLoading(false);
            return;
        }

        // Verify the user credentials
        const uid = await verifyUserCredentials(username, password);
        if (!uid) {
            setError('Invalid username or password');
            setIsLoading(false);
            return;
        }

        // Fetch user details
        const userDetails = await getUserDetails(uid);
        if (!userDetails) {
            setError('Failed to retrieve user details');
            setIsLoading(false);
            return;
        }

        // Set the user in the context
        localStorage.setItem('uid', uid);
        dispatch({ type: 'LOGIN', payload: userDetails });
        setIsLoading(false);
    };

    return { login, isLoading, error };
}