import React from 'react';
import { useAuth } from '../hooks/useAuth';

const MyPage: React.FC = () => {
    const { userdata } = useAuth();

    return (
        <>
            <div>
                <h1>Velkommen til MIN SIDE</h1>
                <p>Dette er en enkelt tekst for å se hvordan designet av teksten er på nettsiden.</p>
                {userdata && (
                    <div>
                        <p>Full Name: {userdata.fullName}</p>
                        <p>Email: {userdata.email}</p>
                        <p>Role: {userdata.role}</p>
                        <p>Level: {userdata.level}</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default MyPage;