import React from 'react';

const DictionaryPage: React.FC = () => {
    return (
        <>
            <div>
                <h1>Velkommen til OPPSLAGSVERK</h1>
                <p>Dette er en enkelt tekst for å se hvordan designet av teksten er på nettsiden.</p>
            </div>
        </>
    );
};

export default DictionaryPage;