import React from 'react';
import CourseCard from './CourseCard';
import './CourseList.css';
import { ModuleType } from './CourseModules';


const CourseList: React.FC<{ modules: ModuleType[] }> = ({ modules }) => {
    return (
        <div className="course-list">
            {modules.length === 0 ? (
                <p>Ingen moduler tilgjengelige.</p>
            ) : (
                modules.map(module => (
                    <CourseCard
                        key={module.tag}
                        module={module}
                    />
                ))
            )}
        </div>
    );
}

export default CourseList;