import { SCORM_CLOUD_BASE_URL, SCORM_CLOUD_AUTH } from "./config";
import { fetchPaginatedData } from "./ServiceUtilities";

/**
 * Fetches paginated courses from the specified base URL.
 * 
 * @param params - The query parameters to include in the request.
 * @returns A promise that resolves to an array of courses.
 */
async function fetchPaginatedRegistrations(params: { [key: string]: string }): Promise<any[]> {
    return fetchPaginatedData('registrations', params, 'registrations');
}

export async function isUserRegistered(registrationId: string) {
    try {
        const response = await fetch(`${SCORM_CLOUD_BASE_URL}/${registrationId}`, {
            method: 'HEAD',
            headers: {
                'Authorization': SCORM_CLOUD_AUTH,
                'accept': 'application/json'
            }
        });
        return response.ok;
    } catch (error) {
        console.error('Failed to check registration status:', error);
        return false;
    }
}


export async function getRegistrationsByLearner(learnerId: string) {
    return fetchPaginatedRegistrations({ learnerId: learnerId });
}

export async function getRegistrationsByCourse(courseId: string) {
    return fetchPaginatedRegistrations({ courseId: courseId });
}

export async function fetchLaunchUrl(registrationId: string) {
    const response = await fetch(`${SCORM_CLOUD_BASE_URL}/registrations/${registrationId}/launchLink`, {
        method: 'POST',
        headers: {
            'Authorization': SCORM_CLOUD_AUTH,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "redirectOnExitUrl": "closer"
        })
    });
    if (!response.ok) {
        throw new Error('Failed to fetch launch URL');
    }

    const data = await response.json();
    return data.launchLink;
}