import React from "react";
import QRCode from "react-qr-code";
import './QrCode.css';

interface QrCodeProps {
  selectedCourse: any;
}

const QrCode: React.FC<QrCodeProps> = ({ selectedCourse }) => {
  const attendanceUrl = `${window.location.origin}/register-attendance?courseId=12345`; // Example URL, replace with actual course ID

  return (
    <div>
      <h2>Scan this QR code to register attendance</h2>
      <QRCode value={attendanceUrl} />
    </div>
  );
};

export default QrCode;