import './LoginPage.css';
import { useState } from 'react';
import Login from '../components/login-register/Login';
import Register from '../components/login-register/Register';


const LoginPage: React.FC = () => {
    const [isLogin, setIsLogin] = useState<boolean>(true);

    return (
        <div className="login-wrapper">
            <button className={`swap-btn ${isLogin}`} onClick={() => setIsLogin(!isLogin)}>
                {isLogin ? 'Registrer deg' : 'Logg inn'}
            </button>
            
            <div className="login-content">
                {isLogin ? <Login /> : <Register />}
            </div>
        </div>
    );
};

export default LoginPage;