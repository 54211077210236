export interface ModuleType {
    level: string;
    tag: string;
    title: string;
    image: string;
}

export const basisModules: ModuleType[] = [
    {
        level: "basis",
        tag: "plivo",
        title: "PLIVO",
        image: "plivo.png"
    },
    {
        level: "basis",
        tag: "lover og regler",
        title: "Lover og regler",
        image: "lover-og-regler.png"
    }
];

export const tjenestetilpassetModules: ModuleType[] = [
    {
        level: "tjenestetilpasset",
        tag: "taktikk",
        title: "Taktikk",
        image: "taktikk.png"
    },
    {
        level: "tjenestetilpasset",
        tag: "sanitet",
        title: "Sanitet",
        image: "sanitet.png"
    },
    {
        level: "tjenestetilpasset",
        tag: "oppdragsprosess",
        title: "Oppdragsprosess",
        image: "oppdragsprosess.png"
    }
];

export const spesialistModules: ModuleType[] = [];