import './FeedbackPage.css';

const Test: React.FC = () => {
  return (
    <div>
        <h1>Tilbakemelding</h1>
        <p>Dette er en enkelt tekst for å se hvordan designet av teksten er på nettsiden.</p>
    </div>
  );
};

export default Test;