import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import './CourseDetails.css';
import TeachingPlan from './TeachingPlan';
import QrCode from '../components/qrcode/QrCode';
import ListParticipants from '../components/templates/ListParticipants';
import { SlArrowRight } from 'react-icons/sl';
import { CiEdit } from "react-icons/ci";
import { getInstructorClasses } from '../api/database/ClassService';
import CoursePartisipants from './CourseParticipants';

const CourseDetails: React.FC = () => {
  const { userdata } = useAuth();
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [courses, setCourses] = useState<any[]>([]); // State to store fetched classes
  

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const InstructorId = userdata.uid;
        const fetchedClasses = await getInstructorClasses(InstructorId);
        if (fetchedClasses) {
          setCourses(fetchedClasses);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
  
    fetchCourses(); // Call the fetchCourses function
  }, []); // Add an empty dependency array to run the effect only once

  const handleButtonCourseClick = (course: any) => {
    setSelectedCourse(course);
  };

  const handleButtonInformationClick = (buttonIndex: number) => {
    setActiveButton(buttonIndex);
  };

  const handleEditClick = () => {
    // Placeholder function for handling the edit button click
    // This must be changed to add feature of editing the course information
    console.log("Edit button clicked");
  };




  const renderComponent = () => { //Renders based on the pressed button displayed on the page
    switch (activeButton) {
      case 1:
        return < ListParticipants selectedCourse={selectedCourse}/>;
      case 2:
        return < TeachingPlan />;
      case 3:
        return < QrCode selectedCourse={selectedCourse}/>;
      case 4:
        return < CoursePartisipants selectedCourse={selectedCourse}/>;
      default:
        return null;
    };
  };

  return (
    <div>
      <div className="courseinfo_background">
        <div className="list-course-general" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="list-course-general-box-a">
            <ul>
              {courses.map((course) => (
                <li key={course.cid}>
                  <div className="course-item">
                    <button
                      className={`list-course-button-imp ${selectedCourse && selectedCourse.cid === course.cid ? 'selected' : ''}`}
                      onClick={() => handleButtonCourseClick(course)}
                    >
                      {course.name}
                    </button>
                    <div className="arrow-container">
                      <span className="arrow-icon">
                        <SlArrowRight />
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="list-course-general-box-b">
            {selectedCourse && (
              <ul>
                <li>{selectedCourse.name}</li>
                <li><span>Tid:</span> {new Date(selectedCourse.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</li>                <li><span>Sted:</span> {selectedCourse.place}</li>
                <li><span>Varighet:</span> {selectedCourse.duration.toString()}</li>
              </ul>
            )}
          </div>

          {selectedCourse && (
            <div className="list-course-general-box-c">
              <button className="edit-button" onClick={handleEditClick}>
                <CiEdit />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="box-d">
        <div className="button3-container">
          <button
            className={`module-button3 ${activeButton === 1 ? 'active' : ''}`}
            onClick={() => handleButtonInformationClick(1)}
          >
            Hent deltakerliste
          </button>

          <button
            className={`module-button3 ${activeButton === 2 ? 'active' : ''}`}
            onClick={() => handleButtonInformationClick(2)}
          >
            Vis undervisningsplan
          </button>

          <button
            className={`module-button3 ${activeButton === 3 ? 'active' : ''}`}
            onClick={() => handleButtonInformationClick(3)}
          >
            Generer QR-kode
          </button>

          <button
            className={`module-button3 ${activeButton === 4 ? 'active' : ''}`}
            onClick={() => handleButtonInformationClick(4)}
          >
            Registrer deltakere
          </button>
        </div>
        <div className="underline3"></div>
      </div>
      {renderComponent()}
    </div>
  ); 

};

export default CourseDetails;