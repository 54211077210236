import React, { useState } from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { SlArrowRight } from "react-icons/sl";
import { GrGroup } from "react-icons/gr";
import { MdComputer } from "react-icons/md";
import './ListCourses.css';

interface ListItem {
  id: number;
  name: string;
  role: string;
  type: number;
}

const ListCourses: React.FC = () => {
  const [list] = useState<ListItem[]>([
    { id: 1, name: "Leksjonsnavn/ID", role: "Emne", type: 0 },
    { id: 2, name: "Leksjonsnavn/ID", role: "Emne", type: 0 },
    { id: 3, name: "Leksjonsnavn/ID", role: "Emne", type: 1 },
    { id: 4, name: "Leksjonsnavn/ID", role: "Emne", type: 1 },
    { id: 5, name: "Leksjonsnavn/ID", role: "Emne", type: 1 }
  ]);

  const handleNextButton = (id: number) => {
    // Perform action when next button is clicked
  };

  const columns: GridColDef[] = [
    { field: 'name', resizable: false, headerName: 'Leksjonsnavn/ID', width: 400, headerClassName: 'header-name', cellClassName: 'cell-name' },
    { field: 'role', resizable: false, headerName: 'Emne', width: 340, headerClassName: 'header-role', cellClassName: 'cell-role' },
    {
      field: 'actions',
      headerName: '',
      resizable: false,
      headerClassName: 'header-action',
      width: 300,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="list-icon-button1">
            {params.row.type === 0 ? <GrGroup style={{ fontSize: '2rem', marginRight: '70px', marginTop: '-5px' }} /> : <MdComputer style={{ fontSize: '2rem', marginRight: '70px', marginTop: '-5px' }} />}
          </button>
          <button className="list-icon-button2" onClick={() => handleNextButton(params.row.id)}>
            <SlArrowRight style={{ fontSize: '1.5rem', strokeWidth: '1.5', marginLeft: '10px' }} />
          </button>
        </div>
      ),
    },
  ];

  const getRowClassName = () => 'custom-row-class';

  return (
    <div className="list-container-course-planbank">
      <div className="table-container-planbank">
        <DataGrid
          rows={list}
          columns={columns}
          getRowClassName={getRowClassName}
          pageSizeOptions={[10]}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnResize
        />
      </div>
      
      <div className="empty-box-for-filling"></div>
    </div>
  );
};

export default ListCourses;
