import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { Layout } from './Layout';
import { UserRole } from './types/UserTypes';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DictionaryPage from './pages/DictionaryPage';
import NotFoundPage from './pages/NotFoundPage';
import CourseInstructorPage from './pages/CourseInstructorPage';
import ModulesPage from './pages/ModulesPage';
import MyPage from './pages/MyPage';
import CreateCoursePage from './pages/CreateCoursePage';


export default function App() {
    const { userdata } = useAuth()!;

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={!userdata ? <LoginPage /> : <Navigate to='/' />} />
                <Route path='/' element={<Layout />}>
                    <Route index element={<Navigate to='/home' />} />
                    <Route path='/home' element={userdata ? <HomePage /> : <Navigate to='/login' />} />
                    <Route path='/dictionary' element={userdata ? <DictionaryPage /> : <Navigate to='/login' />} />
                    <Route path='/modules' element={userdata ? <ModulesPage /> : <Navigate to='/login' />} />
                    <Route path='/mypage' element={userdata ? <MyPage /> : <Navigate to='/login' />} />
                    <Route path='/courseinstructor' element={userdata && userdata.role >= UserRole.Instructor ? <CourseInstructorPage /> : <Navigate to='/login' />}/>
                    <Route path='*' element={<NotFoundPage />} />
                    <Route path='/createcourse' element={userdata && userdata.role >= UserRole.Instructor ? <CreateCoursePage /> : <Navigate to='/login' />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}