import './CourseCard.css';
import React, { useState, useEffect } from 'react';
import { getCoursesByTag } from '../../api/scorm_cloud/CourseService';
import { getRegistrationsByLearner, fetchLaunchUrl } from '../../api/scorm_cloud/RegistrationService';
import { useAuth } from '../../hooks/useAuth';
import { ModuleType } from './CourseModules';

const CourseCard: React.FC<{ module: ModuleType }> = ({ module }) => {
    const { userdata } = useAuth()!;
    const [courses, setCourses] = useState<any[]>([]);
    const [registrations, setRegistrations] = useState<any[]>([]);

    const handleTakeCourseClick = async (uid: string, cid: string) => {
        try {
            const launchUrl = await fetchLaunchUrl(`${uid}.${cid}`);
            window.open(launchUrl, '_blank');
        } catch (error) {
            console.error('Failed to launch course:', error);
        }
    };

    const isUserRegisteredForCourse = (course: any): boolean => {
        return registrations.some(registration => registration.course.id === course.id);
    };


    useEffect(() => {
        getCoursesByTag(module.level, module.tag)
            .then((result) => {
                setCourses(result);
            })
    }, [module]);

    useEffect(() => {
        getRegistrationsByLearner(userdata.uid)
            .then((result) => {
                setRegistrations(result);
            })
    }, []);

    return (
        <div className="course-card">
            <div className="course-card-image" style={{ backgroundImage: `url(/images/courses/${module.image})` }}>
                <div className="image-overlay">
                    <span>Velg leksjon</span>
                    <ul>
                        
                        {courses.map((course) => (
                            <li
                                key={course.id}
                                className={`course-card-list-item ${isUserRegisteredForCourse(course) ? '' : 'disabled'}`}
                                onClick={() => handleTakeCourseClick(userdata.uid, course.id)}>
                                {course.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="course-card-info">
                <span className="course-card-title">{module.title}</span>
            </div>
        </div>
    );
};

export default CourseCard;