import React from 'react';
import './TeachingPlan.css';

const TeachingPlan: React.FC = () => {
    const handleClick = (item: string) => {
        // Do something when a button is clicked
        console.log(item);
    };

    return (
        <div className='teaching-plan-box'>
            <div className='information-plan'>
                Undervisningsplaner tilknyttet valgt leksjon:
            </div>
            
            <div className='text-link-plan'>
                <ul>
                    <li><button onClick={() => handleClick('Item 1')}>
                        Undervisningsplan våpenopplæring
                    </button></li>
                    <li><button onClick={() => handleClick('Item 2')}>
                        Undervisningsplan skytetrening
                    </button></li>
                    <li><button onClick={() => handleClick('Item 3')}>
                        Undervisningsplan xxxxx
                    </button></li>
                    <li><button onClick={() => handleClick('Item 4')}>
                        Undervisningsplan yyyyy
                    </button></li>
                    <li><button onClick={() => handleClick('Item 5')}>
                        Undervisningsplan zzzzz
                    </button></li>
                    {/* Must automate this to fetch data from somewhere */}
                </ul>
            </div>

            <div className="empty-box-for-filling"></div>
        </div>
    );
};

export default TeachingPlan;